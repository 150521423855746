/* global css begin ::::::::::::::::::::::  */

.cursor-pointer {
  cursor: pointer;
}

.profile-bg {
  background: #f9fafb;
  font-size: 14px;
}

.bg-light-danger {
  border: 1px solid var(--error-300, #fda29b);
  background: var(--error-25, #fffbfa);
}

.btn-light-theme {
  border-radius: 8px;
  border: 1px solid #e5faff;
  background: #e5faff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-weight: bold;
  color: #054757;
  font-size: 14px;
  font-weight: 600;
}

.cover-image {
  min-height: 250px;
  background-image: url(https://en.defacto.nl/images/blog/social/color-../ima-for-loading-images-1200x630-85029d65.jpg);
  background-image: url('data:image/svg+xml,<svg width="967" height="240" viewBox="0 0 967 240" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="968" height="240" fill="%23005A70"/></svg>');
  background-size: cover;
  background-repeat: no-repeat;
}

.cover-image {
  min-height: 250px;
  background-image: url(images/cover-image.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.image-holder-main {
  width: 160px;
  height: 160px;
  margin-top: -60px;
}

.image-holder {
  border-radius: 50%;
  border: 4px solid var(--White, #fff);
  background: var(--brand-50, #e5faff);
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  width: 160px;
  height: 160px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 2.5rem;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.btn-theme {
  border-radius: 8px;
  border: 1px solid var(--brand-600, #054757);
  background: var(--brand-600, #054757);
  color: #fff;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 14px;
}

.btn-theme:disabled {
  color: #fff;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--gray-300, #d0d5dd);
}

.btn-border {
  border-color: #d0d5dd;
  font-size: 14px;
}

.text-theme {
  --brand-500: #005a70;
  color: var(--brand-500, #005a70);
}

.nav-pills .nav-link {
  color: #083945;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: #e5faff !important;
  color: #083945;
}

.tales-upload-box {
  /* display: flex; */
  /* width: 192px;
  height: 181px; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
  border-radius: 16px;
  background: var(--gray-100, #f2f4f7);

  /* Shadow/lg */
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.newTalesDesign {
  position: relative;
  background-color: #b2f0ff57;
}
.newTalesDesign .new-tales-position {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.btn-upload {
  display: inline-flex;
  width: 36px;
  height: 36px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  background: var(--brand-100, #b2f0ff);
}

.progress {
  background-color: #f2f4f7;
}

.progress-bar {
  background: #12b76a;
}

.nav-pills .nav-link {
  border: none !important;
}

.tab-content h5,
.tab-content h6 {
  color: #344054;
}

/* modal css begin::::::::::::  */

.modal {
  --bs-modal-padding: 1.5rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-header-padding-x: 1.5rem;
  --bs-modal-header-padding-y: 1.5rem;
  --bs-modal-header-padding: 1.5rem 1.5rem;
}

/* -------------------------end  */

/*User roles page css strat ====================================================================== here*/
ul#tree1 li > li {
  margin-bottom: 10px;
}

ul#tree1 li .bg {
  border-radius: 6px;
  background: var(--gray-100, #f2f4f7);
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

ul#tree1 li > ul {
  padding-left: 15px;
  margin: 10px 0;
}

.owl-nav {
  justify-content: flex-end;
  display: flex;
}

/* owl carousel ---------------  */
.owl-nav {
  text-align: right;
}

.owl-nav button {
  padding: 10px;
  display: inline-block;
  margin: 0 5px;
  border: none;
  outline: none;
  border: none !important;
}

button.owl-prev,
button.owl-next svg {
  fill: #101828;
}

.owl-nav button.disabled svg {
  fill: #d0d5dd;
}

.owl-dots button span {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: var(--brand-100, #b2f0ff);
  margin: 3px;
  display: inline-block;
  border: none !important;
  outline: none;
}

.owl-dots button.active span {
  background: var(--brand-100, #083945);
  border: none !important;
  outline: none;
}

.owl-dots {
  text-align: center;
}

.public-post h3 {
  color: #344054;
  font-size: 14px;
  font-weight: 600;
}

.public-post h3 {
  color: #344054;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.public-post small {
  color: #344054;
  font-size: 12px;
}

.public-post p {
  color: #101828;
}

.share-tales {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.95);
  padding: 15px;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  margin-top: -58px;
  z-index: 9;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.flex-shrink-0.hour-time,
.hour-time {
  color: #98a2b3;
}

.like {
  color: #005a70;
  font-weight: 600;
}

input.form-control,
select.form-select {
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: auto;
  padding: 8px;
  color: #667085;
  font-size: 14px;
}

.uploadheading {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

a.page-link {
  text-align: center;
  padding: 10px 20px;
  border: none;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 10px;
  border-radius: 4px;
  background: none;
}

.active > .page-link,
.page-link.active {
  border-radius: 8px;
  background: var(--brand-50, #e5faff);
  color: #054757;
}

.disabled > .page-link,
.page-link.disabled {
  background: none;
}

.page-link:focus {
  box-shadow: none;
  background: none;
  color: #667085;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none;
}

.following-btn {
  background: #fef0c7;
  color: #054757;
  border: 1px solid #fef0c7;
}

.follow-btn {
  background: #fec84b;
  border: 1px solid #fec84b;
  color: #054757;
}

.add-buddies {
  background: #054757;
  color: #fff;
}

@media (max-width: 767px) {
  .position-relative.w-25 {
    width: 100% !important;
  }
}

/**/
.gray-900 {
  color: #101828;
}

.business_list dt {
  color: #101828;
  font-size: 14px;
  font-weight: 700;
}

.business_list dd {
  color: #101828;
  font-size: 14px;
}

ul.nav.nav-pills.vehicles-group li a {
  border: 1px solid var(--gray-300, #d0d5dd) !important;
  color: #344054;
  font-size: 14px;
  border-radius: 0;
}

ul.nav.nav-pills.vehicles-group li:first-child > a {
  border-radius: 5px 0 0px 5px;
}

ul.nav.nav-pills.vehicles-group li:last-child > a {
  border-radius: 0px 5px 5px 0px;
}

.imgplaceholder {
  display: flex;
  width: 192px;
  height: 216px;
  padding: 103px 0px 93px 0px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 16px;
  background: var(--gray-100, #f2f4f7);

  /* Shadow/lg */
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

h3.businessheading {
  color: var(--gray-700, #344054);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin: 30px 0;
}

.uploaded {
  border-radius: 8px;
  border: 1px solid #005a70 !important;
}

.owl-stage {
  padding-left: 0px !important;
}

.form-check-input:checked[type='radio'] {
  background: url(images/radio-button.svg) no-repeat;
  background-size: 100% 100%;
}

.form-check-input:focus {
  box-shadow: none;
}

textarea.form-control {
  color: #667085;
  font-size: 14px;
}

strong u {
  color: #667085;
  font-weight: 600;
}

.font18-black {
  color: #000;

  /* Text lg/Semibold */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.w-60 {
  width: 60px;
}

.form-check-input:checked[type='checkbox'] {
  background: url(images/check-re.svg) !important;
  background-repeat: no-repeat !important;
}

.form-check-input:checked {
  background-color: #054757;
  border: 1px solid #054757 !important;
}

.custom-counter {
  margin-left: 0;
  padding-right: 0;
  list-style-type: none;
  font-size: 14px;
}

.custom-counter li {
  counter-increment: step-counter;
  padding-left: 38px;
  position: relative;
  color: #000;
}

.custom-counter li::before {
  content: counter(step-counter);
  margin-right: 5px;
  font-size: 80%;

  border: 4px solid var(--brand-50, #e5faff);
  background: var(--brand-100, #b2f0ff);
  color: #054757;
  font-weight: bold;
  padding: 3px 8px;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
}

.custom-counter h6 {
  font-weight: 600;
  margin: 0;
}

button.underline.p-0 {
  font-weight: 700;
  color: #005a70;
  text-decoration: underline;
}

h4.fw-4 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

button.back-btn.text-start {
  color: var(--gray-500, #667085);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.font16 {
  font-size: 14px;
  color: #000;
}

.btn:hover {
  border: 1px solid var(--brand-600, #054757);
}

@media (min-width: 992px) {
  .main-wrapper {
    position: relative;
    overflow: hidden;
    width: 80%;
    background: var(--gray-50, #f9fafb);
    -webkit-transition: 0.5s all;
    -o-transition: 0.5s all;
    transition: 0.5s all;
  }

  .main-wrapper.active {
    width: 95%;
  }

  .sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fcfcfd !important;
    -webkit-transition: 0.5s all;
    -o-transition: 0.5s all;
    transition: 0.5s all;
    min-height: 100vh;
    width: 20%;
  }
}

/*tale page css start here*/

.shareabl-row.d-flex button:hover > svg,
.shareabl-row.d-flex button:hover > span {
  fill: #80e6ff;
}

.blue_border {
  border: 1px solid #5357b6 !important;
  resize: none;
}

.gray500 {
  color: #667085 !important;
}

.shadowpost_text {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.replybox {
  padding: 24px;
  border-radius: 8px;
}

.rp-loop {
  border-color: #e9ebf0 !important;
}

.dropdown-body {
  border-radius: 8px;
  border: 1px solid var(--gray-100, #f2f4f7);
  background: var(--White, #fff);
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  padding: 10px 14px;
}

input.form-control.blue-border {
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: url(images/dropdown_icon.svg) no-repeat 98% center;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

input.form-control.blue-border:focus {
  border: 1px solid var(--brand-300, #00b8e5);
  /* Shadow/xs focused 4px primary-100 */
  box-shadow:
    0px 0px 0px 4px #f4ebff,
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.share-body .filingfoucs {
  display: none;
}

.share-body .f-foucs:hover,
.share-body .f-foucs:hover > .filingfoucs {
  background: var(--success-100, #d1fadf);
  display: block;
}

.share-body .f-foucs:hover > .hovernone {
  display: none;
}

.uploaded-holder .owl-nav {
  position: absolute;
  right: -57px;
  top: 26%;
  flex-direction: column;
  gap: 7px;
  transform: translate(-50%, -7%);
}

.uploaded-holder button.owl-dot {
  margin-top: 10px;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 16px;
}

.gallery img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.plus-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  border: 2px dashed #6c757d;
  border-radius: 8px;
  height: 100%;
  cursor: pointer;
}

.plus-button img {
  width: 50px;
  /* Adjust the size of the plus icon */
  height: auto;
}

.addmore {
  border-radius: 12px;
  opacity: 0.9;
  background: var(--warning-500, #f79009);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  padding: 10px;
  width: 65px;
  height: 65px;
  color: #fff;
}

a.postpublished_user {
  color: var(--warning-500, #f79009);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.close-filling {
  background: var(--brand-50, #e5faff) !important;
}

.roun-box {
  box-shadow:
    0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}

.cyan-box {
  background: #e5faff;
}

.btn-bg {
  background: #054757 !important;
}

.tale-color {
  color: #005a70;
}

/*other profile view*/
a.request-other-profile {
  color: var(--brand-500, #005a70);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration-line: underline;
}

.backbtn {
  color: var(--gray-500, #667085);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
  display: inline-block;
}

.tales .item .share-tales img {
  display: initial;

  width: auto !important;
}

.liked {
  color: #f79009;
}

.tales-added p {
  font-size: 12px;
  color: #000;
  margin-top: 5px;
}

.taletopempty p {
  font-size: 12px;
  color: #000;
}

.position-relative.mb-3.c-w {
  max-width: 250px;
  margin: 0 auto;
}

.likedthum {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.likedthum button {
  width: 35px;
  height: 35px;
  border-radius: 100px;

  background: var(--Brand-50, #e5faff);
  text-align: center;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.likedthum button:hover > svg {
  fill: #80e6ff;
}

.bar span {
  border-radius: 1.929px;
  background: rgba(255, 255, 255, 0.4);
  height: 3px;
  display: block;
  width: 100%;
}

.content-insted-img {
  background: var(--Warning-300, #fec84b);
  height: 397px;
  color: var(--Black, #000);
  font-family: Knewave;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  padding: 25px;
}

.focusclass .sended {
  opacity: 0;
}

.focusclass:hover > .sended,
.focusclass:hover {
  background: #f9fafb;
  display: block;
  opacity: 1;
}

/* Report post css start here============================ */
.report-post h3 {
  color: var(--Gray-900, #101828);
  /* Text lg/Semibold */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 155.556% */
}

.report-post p {
  color: var(--Gray-500, #667085);

  /* Text sm/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.report-btn .btn {
  border-radius: 8px;
  border: 1px solid var(--Brand-600, #054757);
  background: var(--Brand-600, #054757);
}

.modal-backdrop {
  opacity: 0;
  background: rgba(52, 64, 84, 0.7);
  backdrop-filter: blur(8px);
  opacity: 1 !important;
}

.gallerymodal .owl-nav {
  text-align: right;
  position: absolute;
  align-items: center;
  width: 100%;
  text-align: center;
  left: 0;
  right: 0;
  justify-content: center;
  bottom: -40px;
  fill: fff;
  color: #fff;
}

.gallerymodal .owl-nav button svg {
  fill: #fff;
  color: #fff;
}

button.dropdown-toggle.threedoot::after {
  display: none;
}

ul.dropdown-menu.dropdown-menu-start button {
  padding: 5px;
}

ul.dropdown-menu.dropdown-menu-start.show {
  right: 100% !important;
  width: 158px;
  transform: translate3d(-150px, 28px, -100px) !important;
}

.redbtn {
  color: var(--White, #fff);
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  border-radius: 8px;
  border: 1px solid var(--Error-600, #d92d20);
  background: var(--Error-600, #d92d20);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.deletedpostarea {
  background: var(--Error-50, #fef3f2);

  /* Shadow/md */
  box-shadow:
    0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}

.modalbutton button {
  border-radius: 7.718px;
  background: var(--Brand-50, #e5faff);
  width: 35px;
  height: 35px;
}

.taleymodal .owl-nav {
  justify-content: center;
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  justify-content: space-between;
}
.taleymodal .owl-nav button svg {
  fill: #fff;
}
.taleymodal .owl-nav button.disabled {
  opacity: 0.5;
}
.taleymodal .share-tales img {
  width: auto;
}
.tales .owl-nav {
  text-align: right;
  position: absolute;
  right: 0;
}

/* Report post css ends here============================ */

.slick-slider .slick-slide {
  padding: 0 8px;
}
ul.slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}
ul.slick-dots li {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: var(--brand-100, #b2f0ff);
  margin: 3px;
  border: none !important;
  outline: none;
  font-size: 0px;
  display: block;
}
ul.slick-dots li.slick-active {
  background: var(--brand-100, #083945);
  border: none !important;
  outline: none;
}
.slick-slider svg.bi.bi-chevron-right {
  position: absolute;
  right: 0px;
  bottom: 15px;
}
.slick-slider svg.bi.bi-chevron-left {
  position: absolute;
  right: 27px;
  bottom: 15px;
}
.slick-disabled {
  fill: #d0d5dd;
}

/**/
.top-pixcle {
  top: 18px;
}
.dropdown-body {
  display: none;
}
input.form-control.blue-border.ps-5:focus + .dropdown-body {
  display: block;
}

input.form-control.blue-border.ps-5:focus {
  background: url(images/navbar-down-icon.svg) no-repeat 98% center;
  box-shadow: none;
}
.modal-content h2,
.modal-content h3,
.modal-content h4,
.modal-content h5 {
  color: #101828;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

/* meetup post css start here============================ */
.meetup h6 {
  color: var(--Gray-900, #101828);
  /* Display sm/Medium */
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px; /* 126.667% */
}
.meetup h3 {
  color: var(--gray-700, #344054);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.meetup h2 {
  color: var(--brand-500, #005a70);
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}
.meetup h4 {
  color: var(--gray-500, #667085);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.meetup h5 {
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
.meetup p {
  color: var(--gray-500, #667085);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.meetp p {
  color: var(--gray-900, #00050f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.meetup-active button {
  background-color: #d1fadf;
}
.past-meetup button {
  background-color: #f2f4f7;
}
.meetup .meetup-im {
  width: 210px;
}
.meetup .meetup-im img {
  object-fit: cover;
  width: 100%;
}
.meetup button.send svg:hover {
  fill: #fdb022ed;
}
.meetup .count button {
  color: var(--Brand-700, #083945);

  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.meetup ul.dropdown-menu {
  background: #fafeff;
  padding: 10px 5px;
  border-radius: 10px;
}
.meetup ul.dropdown-menu li {
  padding: 5px 0;
}
.meetup ul.dropdown-menu li a {
  color: var(--Gray-700, #344054);

  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.meetup-im.clr {
  background: #fef0c7;
}
.interested-modual h2 {
  color: var(--Gray-900, #101828);

  /* Text lg/Semibold */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
}

.meettile a {
  color: var(--Gray-900, #101828);

  /* Text lg/Semibold */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
}
.modual-cntnt h3 {
  color: var(--Gray-900, #101828);

  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.modual-cntnt p {
  color: #000;
  /* Text xs/Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}
.meetup .stars p {
  color: var(--Brand-500, #005a70);

  /* Text xs / Bold */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 150% */
}
.meetup .stars span {
  color: var(--Warning-500, #f79009);
  /* Text xs / Bold */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 150% */
}
.gobck button {
  color: var(--Gray-500, #667085);

  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.createevent label {
  color: var(--Gray-700, #344054);
  font-size: 14px;
  font-weight: 500;
}
/* css */
.meetup ul.dropdown-menu li:hover a.dropdown-item.hide-on-hover {
  display: none;
}
.meetup ul.dropdown-menu li:hover a.dropdown-item.show-on-hover {
  display: block !important;
  background-color: #d1fadf;
}
.bgadmin {
  background: #fffaeb;
  padding: 10px;
  border-radius: 10px;
}

.fri p {
  color: var(--gray-500, #667085);
}
.map-pin p {
  color: var(--gray-500, #667085);
}
.av2 {
  margin-left: -15px;
}
/*Filters page css start here*/
.btn-requested {
  border-radius: 8px;
  background: var(--Success-500, #12b76a);
  color: #fff;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.Filters .filterheading {
  font-size: 18px;
  font-weight: 600;
  color: #005a70;
}

.Filters p {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: var(--Brand-400, #007a99);
}
.Filters h4 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-top: 14px;
  color: var(--Brand-500, #005a70);
}

.Filters label.form-check-label {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #000;
}
.Filters .check {
  margin-top: 14px;
}
.filterheadung-2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  color: #005a70;
}
.heading-according-to-info {
  color: var(--Gray-700, #344054);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.applied {
  font-weight: 600;
  margin-top: 4px;
}
span.clear {
  color: #005a70;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-decoration-line: underline;
}
.dropdown-toggle::after {
  display: none;
}
.modal .public-post h3 {
  color: #344054;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
/* .form-check.form-switch .form-check-input:checked[type=checkbox]{background: #000 !important;} */
.Filters.event {
  width: 250px;
  height: 500px;
  overflow: hidden;
  overflow: auto;
  overflow-x: hidden;
}
/* meetup post ends  here============================ */

.form-switch .form-check-input {
  background: #f2f4f7 url(images/toggle.svg) no-repeat left center !important;
}
.form-switch .form-check-input:checked[type='checkbox'] {
  background: #000 url(images/toggle.svg) no-repeat left center !important;
  background-size: contain;
  background-position: right center !important;
}

/* Membership css start here */
.membership-title h6 {
  color: var(--Brand-600, #054757);

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.membership-title h3 {
  color: var(--Gray-900, #101828);

  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 125% */
  letter-spacing: -0.96px;
}
.membership-title p {
  color: var(--Gray-500, #667085);

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}
.member-title h2 {
  color: var(--Gray-900, #101828);
  text-align: center;

  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 125% */
  letter-spacing: -0.96px;
}
.member-plan h5 {
  color: var(--Gray-900, #101828);
  text-align: center;

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}
.member-plan p {
  color: var(--Gray-500, #667085);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.member-txt .mbr-txt p {
  color: var(--Gray-500, #667085);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.member-btn {
  border-radius: 8px;
  border: 1px solid var(--Brand-600, #054757);
  background: var(--Brand-600, #054757);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 6px 0;
}
.member-btn button {
  color: var(--White, #fff);

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.button-box {
  position: relative;
  border-radius: 16px;
  background: rgba(253, 209, 189, 0.5);
  mix-blend-mode: multiply;
  display: inline;
  padding: 7px 4px;
}
.toggle-btnn {
  background: none;
  border: none;
  border-radius: 16px;
  padding: 0px 16px;
}
.light {
  background: #fcfcfd;
  opacity: 0.5;
}
/* Membership css ends here */

.requested {
  background: #12b76a;
  color: #fff;
}
.unblock-user {
  background: #f04438;
  color: #fff;
}

/*Account page billing tab css*/
table button.btn {
  color: #083945;
  font-weight: 600;
  font-size: 14px;
}
.billing-table table tr td {
  color: #667085;
  font-size: 14px;
}
.billing-table table tr td .form-check label {
  color: #101828;
  font-weight: 500;
}
.billing-table table thead tr th {
  color: #667085;
  font-size: 12px;
  font-weight: 500;
}
.grayshade {
  font-size: 20px;
}
.billing-table table tr td {
  border-bottom: 1px solid #eaecf0;
}
.table-striped > tbody > tr * {
  background: none;
  box-shadow: none;
}
/**/
/*=================== Membership css start here ===================*/
.basic-cntnt h2 {
  color: var(--Gray-900, #101828);
  text-align: center;

  /* Display lg/Semibold */
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 125% */
  letter-spacing: -0.96px;
}
.basic-cntnt h3 {
  color: var(--Gray-900, #101828);
  text-align: center;

  /* Text xl/Semibold */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}
.basic-cntnt p {
  color: var(--Gray-500, #667085);
  text-align: center;

  /* Text md/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.Upgrades h2 {
  color: var(--Gray-500, #667085);

  /* Display xs/Regular */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
}

.available h2 {
  color: var(--Gray-900, #101828);

  /* Display lg/Semibold */
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 125% */
  letter-spacing: -0.96px;
}
.available p {
  color: var(--Gray-500, #667085);
  text-align: center;

  /* Text md/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.reward h3 {
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}
.pricing-txt p {
  color: var(--Gray-500, #667085);

  /* Text lg/Regular */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}
.chekbtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.pricing-card {
  border-radius: 16px;
  border: 1px solid #fee4d9;
  background: #fffbfa;

  /* Shadow/lg */
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}
.gobckbtn button span {
  color: var(--Gray-500, #667085);

  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.membership-title h6 {
  color: var(--Brand-600, #054757);

  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.membership-title h3 {
  color: var(--Gray-900, #101828);

  /* Display md/Medium */
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
}
.membership-title p {
  color: var(--Gray-500, #667085);

  /* Text xl/Regular */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}
.pricing-card .basic-cntnt h4 {
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
button.Custom {
  background-color: #054757;
}
.cancel-membership button {
  color: var(--Gray-500, #667085);

  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
/*=================== Membership css end here ===================*/
/*=================== Membership Gold plan css start here ===================*/
.pricing-txt {
  gap: 15px;
}
.Gold-plan {
  border-radius: 16px;
  border: 1px solid #fee4d9;
  background: #fffbf1;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}
.Platinu-plan {
  border-radius: 16px;
  border: 1px solid #fee4d9;
  background: #e3f1ffbf;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}
/*=================== Membership  Gold plan css end here ===================*/

/* jobs portal table css start here */
.table-striped > tbody > tr.uniq {
  background: #fef3f2 !important;
  box-shadow: none;
}

.table-striped a {
  color: var(--Gray-500, #667085);
  /* Text sm/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.edit_color-btn {
  background: var(--Brand-50, #e5faff);
}
.btntext {
  color: var(--Brand-700, #083945);

  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.hidenavlink {
  display: none !important;
}
#show-on-hover:hover .show-on-hover {
  display: inline-block !important;
  /* background-color: #D1FADF; */
}
#shortlist {
  color: var(--Success-700, #027a48);
  text-align: center;
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  border-radius: 16px;
  background: var(--Success-50, #ecfdf3);
}

/* jobs portal table css ends here */
/* couch services css start here */
.red {
  background-color: #d92d20;
  color: #fff;
}
.editbtn h5 {
  color: var(--Gray-700, #344054);

  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.meetip .meetup-im {
  width: 210px;
  height: 274px;
}
/* couch services css ends here */

/*===================================== js calender css start here ============================================*/
.wrapper {
  margin: 15px auto;
  max-width: 1100px;
}

.container-calendar {
  background: #ffffff;

  max-width: 100%;
  margin: 0 auto;
  overflow: auto;
}
.button-container-calendar {
  display: flex;
  justify-content: flex-end;
  gap: 22px;
}
.button-container-calendar button {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: none;
  color: #190202;
  border: 1px solid #c1cfd0;
  border-radius: 100px;
  padding: 5px 13px;
}

.table-calendar {
  border-collapse: collapse;
  width: 100%;
}

.table-calendar td,
.table-calendar th {
  padding: 5px;
  border: 1px solid #e2e2e2;
  text-align: center;
  vertical-align: top;
}

.date-picker.selected {
  font-weight: bold;
  outline: 1px dashed #00bcd4;
}

.date-picker.selected span {
  border-bottom: 2px solid currentColor;
}

/* sunday */
.date-picker:nth-child(1) {
  color: red;
}

/* friday */
.date-picker:nth-child(6) {
  color: green;
}

#monthAndYear {
  text-align: center;
  margin-top: 0;
}

.button-container-calendar {
  position: relative;
  margin-bottom: 1em;
  overflow: hidden;
  clear: both;
}

#previous {
  float: left;
}

#next {
  float: right;
}

.footer-container-calendar {
  margin-top: 1em;
  border-top: 1px solid #dadada;
  padding: 10px 0;
}

.footer-container-calendar select {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #ffffff;
  color: #585858;
  border: 1px solid #bfc5c5;
  border-radius: 3px;
  padding: 5px 1em;
}
.fc-toolbar.fc-header-toolbar .fc-left button {
  border-radius: 100%;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  overflow: hidden;
}
.fc-toolbar.fc-header-toolbar .fc-right button {
  border-radius: 23px;
  margin-right: 10px;
}

.fc-toolbar .fc-left {
  float: right !important;
}
.fc-toolbar .fc-right {
  float: left !important;
}
button.fc-today-button.fc-button.fc-state-default.fc-corner-left.fc-corner-right {
  display: none;
}
button.fc-basicDay-button.fc-button.fc-state-default.fc-corner-right {
  display: none;
}
.fc-button-group button {
  border-radius: 20px;
  border: 2px solid var(--Grey-Blue-97, #f5f6f7);
  background: var(--White, #fff);
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
  color: var(--Grey-Blue-60, #6b7a99);
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;

  /* Black 12 */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px; /* 166.667% */
  text-transform: capitalize;
}
/*===================================== js calender css ends here ============================================*/

/*=====================================calender table css start here ============================================*/
.calender-table table tr th {
  border: 1px solid #eaecf0;
}
.calender-table table thead tr th {
  color: #667085;
  font-size: 12px;
  font-weight: 500;
}
.calender-table table tr td {
  border: 1px solid #eaecf0;
}

.table-striped > tbody > tr:nth-child(3)td {
  background-color: #e5faff;
}
/*=====================================calender table css end here ============================================*/

/*=====================================Booking step form  css start here ============================================*/
#container {
  max-width: 550px;
}

.step-container {
  position: relative;
  text-align: center;
  transform: translateY(-43%);
}

.step-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #101828;
  border: 2px solid #101828;
  line-height: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer; /* Added cursor pointer */
}

.step-line {
  position: absolute;
  top: 16px;
  left: 50px;
  width: calc(100% - 100px);
  height: 2px;
  background-color: #007bff;
  z-index: -1;
}

#multi-step-form {
  overflow-x: hidden;
}
/*=====================================Booking step form  css ends here ============================================*/

/*================================================couch services new css start here============================== */

/* .freight_driver h5{

} */
.repeat_customer {
  /* background-color: #027A48; */
  background: var(--Brand-50, #ecfdf3);
}
.prof-subtext {
  color: var(--Gray-900, #101828);

  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.email-prof-content h5 {
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.email-prof-content span {
  color: var(--Gray-900, #101828);

  /* Text md/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.stay-request h5 {
  color: var(--Gray-900, #101828);

  /* Text lg/Medium */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}
.meetup-active .cancelby_you {
  background-color: #fef3f2;
  color: var(--Error-700, #b42318);
  text-align: center;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}
.job-portal-form .progress-bar {
  background: #12b76a;
}
#find-couch .different-heading {
  color: var(--Error-800, #912018);
}
.meetup-active,
.acceptbtn,
.requsted-btn,
.reject_btn {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}
.meetup-active .acceptbtn {
  background-color: #ecfdf3;
  color: #027a48;
}
.meetup-active .requsted-btn {
  background-color: #eff8ff;
  color: var(--Blue-700, #175cd3);
}
.meetup-active .reject_btn {
  color: var(--Error-700, #b42318);
  background: #fef3f2;
}
.light-para {
  color: var(--Gray-500, #667085);
}
.highlight-bold {
  color: #000 !important;
  font-family: Inter;
  /* font-size: 20px; */
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
}
.light-bold {
  color: var(--Gray-700, #344054);
  font-weight: 500;
}
ul.nav.nav-pills.vehicles-group a {
  font-weight: 500;
  color: var(--Gray-700, #344054);
}

.diffrent-color {
  color: var(--Brand-500, #005a70) !important;
}
button.disable-btn {
  background-color: #98a2b3 !important;
  border: none;
}
/*================================================couch services new css end here============================== */
/* ++++++++++++++++++++++Responsive css start here+++++++++++++++++++++++++++++++++++++++++++++++ */
@media screen and (max-width: 600px) {
  .meetttxt {
    flex-wrap: wrap;
  }
  .meetup .meetup-im {
    min-height: 274px;
  }
}
/* ++++++++++++++++++++++Responsive css start here+++++++++++++++++++++++++++++++++++++++++++++++ */

.header01 .form-select {
  padding: .375rem 2.25rem .375rem .75rem !important;
}