@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap');

body,
html {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

ol,
ul {
  margin: 0;
  padding: 0;
}
button {
  background: #0000;
  border-color: #0000;
}
a {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
}

ol,
ul {
  list-style-type: none;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.4);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(253, 253, 253, 0.4);
}
/**/

.search input:focus + .results {
  display: block;
}

.search .results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  margin: 0;
  border-radius: 3px;
  background-color: #fdfdfd;
  width: 100%;

  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.statusinfo {
  color: var(--Gray-500, #667085);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 5px;
  margin: 0;
}
.notificationdropdown h5 {
  color: #101828;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.noresult {
  color: #98a2b3;
  font-size: 14px;
}

/**/
body {
  background-color: #fff;
}
.me-10,
.sidebar-body-menu a > .icon {
  margin-right: 10px;
}
.library-table-name span,
.page-center,
.page-flex,
.sidebar-user-img picture,
.users-table picture {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.library-table-name span,
.page-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.page-center {
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  padding: 30px 15px;
}

.main-wrapper {
  position: relative;
  width: 100%;
  background: var(--gray-50, #f9fafb);
}

.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
}

.menu-toggle {
  background-image: url(images/left-slide-arrow.svg);
  width: 24px;
  height: 24px;
}

.search-wrapper button.serchbutton {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 44px;
  height: 100%;
  z-index: 1;
  background: #FFEAB4;
  border-radius: 0 8px 8px 0px;
}

.main-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.42;
  color: #101828;
}

button {
  cursor: pointer;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.sidebar-toggle {
  opacity: 0.6;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.sidebar-toggle:hover {
  opacity: 1;
}

.category__btn.rotated .icon,
.sidebar-toggle.rotated {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.category__btn,
.gray-circle-btn,
.ligt-circle-btn {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
}

.category__btn {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  right: 0;
  top: 13px;
  z-index: 1;
}

.gray-circle-btn,
.ligt-circle-btn {
  width: 40px;
  height: 40px;
  border-width: 0;
}

.gray-circle-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #eff0f6;
}

.ligt-circle-btn {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #f5efff;
}

.sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #fcfcfd !important;
  width: 365px;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
  min-height: 100vh;
}

.sidebar a:focus {
  outline: 0;
}

.sidebar-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 17px 20px;
  border-bottom: 1px solid #eaecf0;
}

.sidebar-body {
  padding: 20px;
}

.sidebar-body-menu a,
.sidebar-user {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.sidebar-body-menu a {
  position: relative;
  padding: 10px;
  color: #344054;
  font-weight: 500;
}

.afternone::after {
  display: none;
}
span.notify {
  border-radius: 5px;
  border: 1.5px solid var(--White, #fff);
  background: var(--orange-300, #feb273);
  width: 10px;
  height: 10px;
  position: absolute;
  right: 5px;
  top: 6px;
}

span.notify.user {
  background: #12b76a;
  top: 36px;
  width: 12px;
  height: 12px;
  right: 0;
}
.dropdown-menu {
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  padding: 0;
  list-style: none;
  border: none;
}
.cat-sub-menu.visible a:hover,
.sidebar-body-menu a.active,
.sidebar-body-menu a.active::after,
.sidebar-body-menu a:hover,
.sidebar-body-menu a:hover::after {
  opacity: 1;
  border-radius: 6px;
  background: #FFEAB4;
}

.sidebar-user {
  border-radius: 10px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.1);
}

.sidebar-body-menu a,
.sidebar-user,
.sidebar-user-img,
.sidebar-user-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.sidebar-user-img {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 8px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-color: #e5faff;
}

.sidebar-user-info {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.sidebar-user__title {
  font-weight: 600;
  color: #344054;
  text-align: left;
}

.logo {
  margin-right: 9px;
}

.logo-text,
.logo-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.logo-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.logo-text {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #fff;
}

.logo-subtitle,
.logo-title {
  font-size: 21px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.86px;
}

.logo-subtitle {
  font-size: 12px;
  font-weight: 500;
  line-height: 2.42;
}

.msg-counter,
.system-menu__title {
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.75px;
}

.main-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.main-nav a:focus {
  outline: 0;
}

.main-nav--bg {
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  background-color: #fff;
  box-shadow:
    0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  padding: 10px 0;
}

.main-nav-end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-nav-end > * {
  margin-right: 15px;
}

.footer-end li:last-child,
.main-btns-wrapper button:last-child,
.main-nav-end > :last-child,
.sort-bar .select-wrapper:last-child .select {
  margin-right: 0;
}

.main-nav .notification,
.main-nav .notification-wrapper {
  position: relative;
}

.main-nav .notification::after {
  display: none;
  content: '';
  position: absolute;
  right: 5px;
  top: -14px;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  border: 1px solid #fff;
  background-color: #f26464;
  border-radius: 5px;
  border: 1.5px solid #fff;
  background: #feb273;
}

.main-nav .notification.active::after {
  display: initial;
}

.main-nav .notification-dropdown {
  right: 0;
  width: 236px;
  padding: 10px;
}

.main-nav .notification-dropdown-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 167px;
}

.main-nav .notification-dropdown__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.22;
  color: #171717;
  margin-bottom: 5px;
}

.main-nav .notification-dropdown__subtitle {
  font-size: 10px;
  line-height: 1.2;
  color: #767676;
}

.main-nav .notification-dropdown-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.main-nav .notification-dropdown-icon.info {
  background-color: rgba(88, 135, 255, 0.1);
  color: #5887ff;
}

.main-nav .notification-dropdown-icon.danger {
  background-color: rgba(242, 100, 100, 0.1);
  color: #f26464;
}

.main-nav .notification-dropdown a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 10px;
  padding-top: 15px;
}

.main-nav .notification-dropdown a.link-to-page {
  padding-top: 17px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #5887ff;
}

.main-nav .nav-user-wrapper,
.search-wrapper {
  position: relative;
}

.main-nav .nav-user-wrapper .users-item-dropdown {
  width: 171px;
  right: 0;
}

.main-nav .nav-user-wrapper .users-item-dropdown svg {
  margin-right: 13px;
}

.main-nav .nav-user-img {
  border-radius: 50%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  width: 40px;
  height: 40px;
  background-color: #f5efff;
}

.search-wrapper input {
  width: 271px;
  background-color: #fff;
  padding: 3px 42px 3px 8px;
  height: 40px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 0px 8px 8px 0px;
}
.select_drop {
  background-color: #fff;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 8px 0px 0px 8px;
  float: left;
  font-size: 14px;
  color: #667085;
  outline: none;
}

.search-wrapper input::-webkit-input-placeholder {
  background-color: #fff;
  font-size: 14px;
  color: #667085;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

.search-wrapper input::-moz-placeholder {
  background-color: #fff;
  font-size: 14px;
  color: #667085;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}
.search-wrapper input:-ms-input-placeholder,
.search-wrapper input::-ms-input-placeholder {
  background-color: #fff;
  font-size: 14px;
  color: #667085;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

.search-wrapper input::placeholder {
  background-color: #fff;
  font-size: 14px;
  color: #667085;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

input:focus {
  outline: 0;
  border: 1px solid rgba(134, 182, 254, 0.5) solid !important;
}
.main {
  padding-top: 20px;
  padding-bottom: 20px;
}

.chart #myChart,
.main-content {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}

.main-content {
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 65vh;
}

.main-title-wrapper {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 34px;
}
@media (min-width: 1200px) {
  .sidebar.hidden {
    width: 64px;
    padding-right: 4px;
    padding-left: 4px;
  }

  .sidebar.hidden .logo-wrapper {
    display: none;
  }

  .sidebar.hidden * {
    font-size: 0;
  }

  .sidebar.hidden .sidebar-head {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .main-nav .sidebar-toggle,
  .sidebar.hidden .category__btn {
    display: none;
  }

  .sidebar.hidden .sidebar-body-menu a {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .sidebar.hidden .sidebar-body-menu a::after {
    right: -4px;
  }

  .sidebar.hidden .sidebar-body-menu a > .icon {
    margin-right: 0;
  }

  .sidebar.hidden .msg-counter {
    right: 10px;
    top: 30%;
  }

  .sidebar.hidden .sidebar-user {
    width: 52px;
    height: 53px;
    margin-right: auto;
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .sidebar.hidden .sidebar-user-img {
    width: 40px;
    height: 40px;
    margin-right: 0;
  }

  .sidebar.hidden .sidebar-user-img img {
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 1199.98px) {
  .sidebar {
    position: fixed;
    z-index: 2;
    overflow-y: auto;
    left: -1000px;
  }
  .sidebar.hidden {
    left: 0;
  }

  .main-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
}
@media (max-width: 767.98px) {
  .main-nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .main-nav-start,
  .main-nav-start input {
    width: 100%;
  }

  .main-nav-end {
    margin-bottom: 20px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
  }
}
/*Side bar css start here*/

.slidelogo {
  display: none;
}

@media (min-width: 768px) {
  .sidebar.hidden .slidelogo {
    display: block;
  }
  .sidebar.hidden .verfication-card {
    display: none;
  }
  .sidebar.hidden .sidebar-user-info {
    display: none;
  }
  .sidebar.hidden .sidebar-body {
    padding: 2px;
  }
}
.sidebar-footer {
  border-top: 1px solid #eaecf0;
  padding: 10px;
}

.transparent-btn:focus {
  outline: 0;
  border: 2px solid #afbcff00 !important;
}
.gap {
  column-gap: 5px;
}
.driver-btn {
  border-radius: 45px;
  font-size: 13px;
  background: #e5faff;
  font-weight: 600;
  color: #08242b;
  padding: 2px 10px;
}
.unverifedbutton {
  color: #d92d20;
  padding: 2px 10px;
  font-weight: 600;
  background: #fee4e2;
  border-radius: 45px;
  font-size: 13px;
}

.verfication-card {
  border-radius: 8px;
  background: #fffaeb;
  margin: 10px;
  padding: 15px;
  font-size: 14px;
  color: #083945;
}
span.sidebar-user__subtitle {
  font-size: 12px;
  text-align: left;
  color: #667085;
}
/*Welcome page css start here*/
.text-center.mb-4.title h2 {
  color: #1d4d64;
  font-weight: 600;
  font-size: 35px;
}

.title p {
  color: #1d4d64;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
}

.contact-box.d-flex {
  border-radius: 8px;
  border: 1px solid #eaecf0;
  background: #fff;
  padding: 15px;
  color: #08242b;
  margin-bottom: 15px;
}

.contact-box.d-flex strong {
  font-weight: 500;
  margin-bottom: 2px;
  display: block;
}
.contact-box.d-flex p {
  font-size: 14px;
  color: #667085;
  margin-bottom: 0px !important;
}

.contact-box.d-flex.active {
  border-radius: 8px;
  border: 1px solid var(--brand-300, #00b8e5);
  background: var(--brand-50, #e5faff);
}

span.circle32 {
  border-radius: 28px;
  border: 4px solid var(--brand-50, #e5faff);
  background: var(--brand-100, #b2f0ff);
  width: 42px;
  height: 42px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.withstatus {
  border-radius: 45px;
  font-size: 10px;
  background: #e5faff;
  font-weight: 600;
  color: #08242b;
  padding: 4px 4px;
  position: absolute;
  top: 66%;
  left: -2px;
}
/*Welcome page css end here*/

/*message notifaction*/

nav button.dropdown-toggle.show {
  background: #e5faff;
  border-radius: 4px;
}

.messagenotifaction {
  max-height: 520px;
  overflow: auto;
  overflow-x: hidden;
}

.messagenotifaction {
  width: 320px;
}
.messagenotifaction h3 strong {
  color: #005a70;
  font-size: 18px;
}
.viewall {
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.time12 {
  font-size: 13px;
}

@media (min-width: 768px) {
  .search .results {
    width: 450px;
  }

  .messagenotifaction {
    width: 320px;
  }
}
.chat-list {
  font-size: 13px;
}
.chat-list h3 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
}
.chat-list p {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
}
.chat-list a.d-flex {
  margin-bottom: 15px;
  position: relative;
  text-decoration: none;
}
.st-statis {
  display: block;
  content: '';
  clear: both;
  position: absolute;
  bottom: 3px;
  left: 34px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
}
.online {
  background: #12b76a;
}
.away {
  background: #f79009;
}

.disturb {
  background: #f04438;
}

.driving {
  background: #7a5af8;
}

.offline {
  background: #d0d5dd;
}

button.notify-accept {
  background: #054757;
  border-radius: 8px;
  color: #fff;
  font-size: 13px;
  padding: 4px 7px;
  font-weight: 600;
}
button.notift-reject {
  background: #fef3f2;
  color: #b42318;
  font-size: 13px;
  padding: 4px 7px;
  border-radius: 8px;
  font-weight: 600;
}
.status.text-center {
  color: #007a99;
  font-weight: 600;
  margin-bottom: 7px;
}
.adminlogin {
  width: 200px;
}
.adminlogin li a {
  font-size: 14px;
  padding: 8px;
}

.fullpagemessage a.d-flex {
  margin-bottom: 15px;
  position: relative;
  text-decoration: none;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: 80vh;
  overflow-y: scroll;
}

/*===========================
     CHAT BOOT MESSENGER
   ===========================*/
#Smallchat .Messages,
#Smallchat .Messages_list {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.chat_close_icon {
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  position: absolute;
  right: 12px;
  z-index: 9;
}
.chat_on {
  position: fixed;
  z-index: 10;
  width: 70px;
  height: 70px;
  right: 15px;
  bottom: 20px;
  color: #fff;
  text-align: center;
  padding: 11px 9px 9px 9px;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  cursor: pointer;
  display: block;
  border-radius: 100px;
  background: #fafeff;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}
.chat_on_icon svg {
  fill: #fadb86;
  font-size: 25px;
  text-align: center;
}

span.chat-icon {
  border-radius: 40px;
  background: var(--brand-25, #fafeff);
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  text-align: center;
  padding-top: 3px;
}
/*
#Smallchat,#Smallchat * {
 box-sizing:border-box; 
 -webkit-font-smoothing:antialiased;
 -moz-osx-font-smoothing:grayscale;
 -webkit-tap-highlight-color:transparent
}
*/
#Smallchat .Layout {
  pointer-events: auto;
  box-sizing: content-box !important;
  z-index: 999999999;
  position: fixed;
  bottom: 20px;
  min-width: 50px;
  max-width: 300px;
  max-height: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-radius: 16px;
  background: #fff;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  -webkit-animation: appear 0.15s cubic-bezier(0.25, 0.25, 0.5, 1.1);
  animation: appear 0.15s cubic-bezier(0.25, 0.25, 0.5, 1.1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  opacity: 0;
  -webkit-transition:
    right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    border-radius 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    background-color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s;
  transition:
    right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    border-radius 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    background-color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s;
}

#Smallchat .Layout-right {
  right: 20px;
}

#Smallchat .Layout-open {
  overflow: hidden;
  min-width: 300px;
  max-width: 300px;
  height: 500px;
  max-height: 500px;
  border-radius: 10px;
  color: #fff;
  -webkit-transition:
    right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    border-radius 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    background-color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
  transition:
    right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    border-radius 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    background-color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
}

#Smallchat .Layout-expand {
  height: 500px;
  min-height: 500px;
  /* display:none; */
}
#Smallchat .Layout-mobile {
  bottom: 10px;
}
#Smallchat .Layout-mobile.Layout-open {
  width: calc(100% - 20px);
  min-width: calc(100% - 20px);
}
#Smallchat .Layout-mobile.Layout-expand {
  bottom: 0;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  border-radius: 0 !important;
}
@-webkit-keyframes appear {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes appear {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
#Smallchat .Messenger_messenger {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 300px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
#Smallchat .Messenger_header,
#Smallchat .Messenger_messenger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
#Smallchat .Messenger_header {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 40px;
  height: 50px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background: #fdb022;

  box-shadow: -100px 100px 150px 0px rgba(52, 64, 84, 0.12);
}

#Smallchat .Messenger_prompt {
  margin: 0;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  margin-left: 45px;
}

@-webkit-keyframes slidein {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slidein {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
#Smallchat .Messenger_content {
  height: 450px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
}
#Smallchat .Messages {
  position: relative;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  -webkit-overflow-scrolling: touch;
}

#Smallchat .Input {
  position: relative;
  width: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 12px 0;
  color: #96aab4;
  background-color: #fff;
  border-radius: 12px;
  border: 2px solid #e2e8f0;
  background: #fff;
}

#Smallchat .Input_field {
  width: 100%;
  border: none;
  padding: 0;
  padding-right: 0px;
  padding-left: 0px;
  padding-left: 20px;
  padding-right: 50px;
  background-color: transparent;
}
#Smallchat .Input_button {
  position: absolute;
  bottom: 13px;
  width: 25px;
  height: 25px;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}
#Smallchat .Input_button-send {
  right: 15px;
}
/* .chat-body{display: none;} */
.Messenger_content .Messages_list .chat-list h3,
.chat-body h3 {
  font-weight: 600;
  font-size: 14px;
  color: #000;
}
.Messenger_content .Messages_list .chat-list p,
.chat-body p {
  font-weight: 600;
  font-size: 12px;
  color: #475467;
  font-weight: 400;
  margin: 0;
}
.Messenger_content .Messages_list .chat-list .time12,
.chat-body .time12 {
  opacity: 0.3;
  color: #000;
  font-weight: 600;
  font-size: 14px;
}

.inlineuser-bg {
  background: #fef0c7;
}
p.bgadmin {
  background: #fffaeb;
  border-radius: 12px;
  padding: 8px 16px;
  color: #000;
  font-size: 13px;
  margin-bottom: 10px !important;
}
p.bguser {
  background: #f79009;
  border-radius: 12px;
  padding: 8px 16px;
  color: #fff;
  font-size: 13px;
  margin-bottom: 10px !important;
}
.greenstatus {
  background: #68d391;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}
.textonline {
  opacity: 0.6;
  color: #000;
  font-weight: 600;
  font-size: 12px;
}
.chatgoback {
  color: var(--gray-500, #667085);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.shadow-sm {
  box-shadow:
    0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1) !important;
}

/*buddies page css start here*/
.buddies-card {
  border-radius: 16px;
  background: #fff;
  padding: 8px;

  box-shadow:
    0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}

.buddies-card h3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #101828;
  margin-top: 15px;
}

.buddies-card .buddies-tagline {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #000;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.position-absolute.buddy-status {
  top: 10px;
  left: 10px;
}

/*Dashboard skip process*/
.idverifaction h1 {
  color: #1d4d64;
  text-align: center;
  font-family: Inter;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
}
.box-secure h3 {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: var(--Black, #000);
}
.box-secure p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: var(--Black, #000);
}
.custom-button {
  background: #005a70 !important;
  color: #fff !important;
  padding: 10px;
  font-weight: 600;
}
.custom-gray {
  background: #e5faff !important;
  padding: 10px;
  font-weight: 600;
}
.backbtn {
  color: var(--gray-500, #667085);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
  display: inline-block;
}
.position-absolute.inputimg {
  top: 24%;
  left: 10px;
}
.bg-input-gray {
  background-color: #f9fafb !important;
  font-weight: 600;
}

/*Step Form*/
.stepform li a {
  color: var(--Brand-700, #083945) !important;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border: none !important;
  padding: 12px 35px;
}
.stepform li a span {
  width: 36px;
  height: 36px;
  background: var(--Brand-500, #005a70);
  border-radius: 100px;
  color: #fff;
  margin-right: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stepform li a.active {
  border-radius: 12px;
  border-bottom: 4px solid var(--Brand-500, #005a70) !important;
}
h4.headingstep {
  color: #1d4d64;
  font-weight: 600;
  font-size: 20px;
}
.comp-icon {
  display: none;
}
li.nav-item.completed a span samp {
  display: none;
}
li.nav-item.completed a span .comp-icon {
  display: block;
}

/* chat bubble css start here =====================================*/
.chatbubble ul.nav.nav-pills.vehicles-group li a {
  border: none;
  color: #344054;
  font-size: 14px;
  border-radius: 0;
}
.chatbubble .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: #e5faff !important;
  color: #083945;
}
.plus-five {
  background: #e5faff;
}
.plus-five {
  display: block;
  content: '';
  clear: both;
  position: absolute;
  bottom: 3px;
  left: 34px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
  color: #054757;
}
.ylwgrp {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #fedf89;
}
.meetups-tab ul.nav.nav-pills.vehicles-group li a {
  border: 1px solid var(--gray-300, #d0d5dd) !important;
  color: #344054;
  font-size: 14px;
  border-radius: 0;
}
.meetups-tab .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: #e5faff !important;
  color: #083945;
}
ul.nav.nav-pills.vehicles-group li:first-child > a {
  border-radius: 5px 0 0px 5px;
}
ul.nav.nav-pills.vehicles-group li:last-child > a {
  border-radius: 0px 5px 5px 0px;
}
.memberss {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px dotted #d0d5dd;
}
.member-list h6 {
  color: var(--Gray-900, #101828);

  /* Text md/Medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.member-list p {
  color: var(--Gray-500, #667085);

  /* Text md/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
#forcolr h3 {
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
}
#forcolr p {
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}
#forcolr .greenp {
  color: var(--Success-500, #12b76a);
}
#forcolr .redp {
  color: var(--Error-500, #f04438);
}
.chatbubble .nav-link {
  padding: 10px 12px;
}
.av2 {
  margin-left: -15px;
}

.stclrx {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
.owner-operator-btn {
  border-radius: 16px;
  background: var(--Brand-50, #e5faff);
  color: var(--Brand-800, #08242b);
  text-align: center;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}
input.form-control.blue-border {
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: url(images/dropdown_icon.svg) no-repeat 98% center;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.gray500 {
  color: #667085 !important;
}
.public-post h3 {
  color: #344054;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.focusclass:hover > .sended,
.focusclass:hover {
  background: #f9fafb;
  display: block;
  opacity: 1;
}
.focusclass .sended {
  opacity: 0;
}
#typing-area {
  background: var(--Brand-25, #fafeff);
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px -3px 16px -4px rgba(16, 24, 40, 0.08);
}
.stop-shairing {
  color: var(--Gray-700, #344054);

  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  padding: 8px 14px;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.shairin-location-text small {
  color: var(--Brand-500, #005a70);
}
.btn-theme {
  border-radius: 8px;
  border: 1px solid var(--brand-600, #054757);
  background: var(--brand-600, #054757);
  color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 14px;
}
.btn:hover {
  border: 1px solid var(--brand-600, #054757);
}
.btn:hover {
  color: var(#000);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.remaining {
  color: var(--Gray-700, #344054);
  text-align: center;
  padding: 2px 8px;
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;

  border-radius: 16px;
  background: #f2f4f7;
}
#shared-location h3 {
  color: var(--Gray-900, #101828);

  /* Text lg/Semibold */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
}
.chat-notify {
  position: absolute;
  /* bottom: 0px; */
  top: -8px;
  left: 46px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}
/* chat bubble css ends here ========================================*/

/* ----------------------------alliance CSS Start----------------------------------------- */
.cancelBtn {
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  background: var(--White, #FFF);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.submitBtn, .submitBtn:hover {
  border-radius: 8px;
  border: 1px solid #FFB703 !important;
  background: #FFB703;
  color: #000000;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.lightBtn, .lightBtn:hover {
  border-radius: 8px;
  border: 1px solid #787878 !important;
  background: #FFF7CA;
  color: #000000;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.text-center.mb-4.title h3 {
  color: #272727;
  font-weight: 600;
  font-size: 28px;
}
.text-center.mb-5.title h3 {
  color: #272727;
  font-weight: 600;
  font-size: 28px;
}
.text-center.mb-4.title p {
  color: #272727;
  font-weight: 400;
  font-size: 16px;
}
.personal .title-upload {
  color: #344054;
  font-weight: 500;
  font-size: 16px;
}
.verifyAccountDiv {
  border-radius: 5px;
  background: #FFFCF6;
  padding: 28px 17px;
}
.roundCircleDiv {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  border: 4px solid #FFF7E2;
  background: #FFEAB7;
}
.privacyPolicy {
  color: var(--Black, #000);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.document-list-card {
  border-radius: 16px;
  background: #FFF;
  padding: 16px;
  /* Shadow/md */
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}
.list-card {
  background: #F9F9F9;
  padding: 16px;
  width: 49%;
}
/* .list-card-header {
  
} */
.personal-detais .document-list-card .list-card .list-body p:first-child {
  font-size: 14px;
  color: var(--Gray-900, #101828);
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}
.personal-detais .document-list-card .list-card .list-body p {
  font-size: 16px;
  color: var(--Gray-900, #101828);
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.sign-contract {
  color: #272727;
  font-weight: 600;
  font-size: 28px;
}
.memberShipDeTil {
  color: var(--Gray-900, #101828);
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px; /* 126.667% */
}
.memberShip-card {
  border-radius: 8px;
  border: 1px solid #1A54A1;
  background: #E3EDFA;
  padding: 22px 24px;
}
.memberShip-card .member-header {
  font-size: 36px;
  color: var(--Gray-900, #101828);
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
}
.memberShip-card .MembershipID {
  color: #727272;

  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 28.5px */
  letter-spacing: -0.38px;
}
.Upgraded-member {
  color: var(--Gray-500, #667085);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.Reward-points {
  top: 0;
  right: 30px;
}
.Reward-points-div {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Reward-points-div .reward-title {
  color: #715100;
  text-align: center;
  font-size: 9.321px;
  font-style: normal;
  font-weight: 500;
  line-height: 4.661px; /* 50% */
  letter-spacing: -0.186px;
}
.Reward-points-div .reward-point {
  color: #2D2D2D;
  font-size: 24.857px;
  font-style: normal;
  font-weight: 700;
  line-height: 17.089px;
  letter-spacing: -0.497px;
}
.rewards-card {
  border-radius: 16px;
  padding: 22px;
  background: linear-gradient(92deg, #FFD149 0.16%, #FDB022 101.59%);
}
.rewards-card .rewards-earned {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 187.5% */
}
.rewards-card .total-reward {
  color: var(--Gray-900, #101828);
  /* Display lg/Semibold */
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 125% */
  letter-spacing: -0.96px;
}
.rewards-card .each-point-reward {
  color: #000;
  /* Text xl/Medium */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
}
.reward-point-history .reward-point-history-title {
  color: var(--Gray-500, #667085);
  /* Text xl/Medium */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
}
.planDiv .pricing-card {
  padding: 40px 16px 0px 16px !important;
}
.planDiv .pricing-card .monthly {
  color: var(--Gray-900, #101828);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 166.667% */
  text-transform: uppercase;
}
.planDiv .pricing-card .priceDiv {
  color: #667085;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  text-decoration-line: strikethrough;
}
.planDiv .totalPriceDiv .dollar {
  color: var(--Gray-900, #101828);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 212.5% */
  letter-spacing: -0.32px;
}
.planDiv .totalPriceDiv .priceD {
  color: var(--Gray-900, #101828);
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 85% */
  letter-spacing: -0.8px;
}
.planDiv .excluding-taxes {
  color: #667085;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.2px;
}
.planDiv .billedMonthly {
  color: var(--Gray-500, #667085);
  text-align: center;
  /* Text md/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.plan-top-first-buttonDiv {
  border-radius: 16px;
  padding: 4px 4px 4px 12px;
  background: #E3F1FF;
  width: fit-content;
  margin: 0 auto;
}
.plan-top-first-buttonDiv p:first-child {
  color: #083945;
  /* Text xs/Medium */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}
.plan-top-first-buttonDiv p:last-child {
  border-radius: 16px;
  padding: 2px 8px;
  color: #083945;
  text-align: center;
  /* Text xs/Medium */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  background: var(--White, #FFF);
}

/* ----------------------------alliance CSS End----------------------------------------- */
/* ----------------------------CSR CSS End----------------------------------------- */

/* Start Login Screen */
.text-center.mb-4.title .titleTextLog {
  color: var(--Gray-500, #667085);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
/* End Login Screen */



/* ----------------------------CSR CSS End----------------------------------------- */